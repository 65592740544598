var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('div',{staticClass:"centered"},[_c('a',{staticClass:"download-programme",attrs:{"href":"https://whodunnit.coliseum.org.uk/Whodunnit-at-the-Coliseum-Programme.pdf","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/download-programme.png"),"alt":"Download the programme","width":"100%"}})]),_c('v-responsive',{attrs:{"aspect-ratio":41 / 35}},[_c('div',{staticClass:"video-block"},[_c('v-responsive',{attrs:{"aspect-ratio":16 / 9}},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.IO.Ended),expression:"!IO.Ended"}],staticClass:"videos fill-height"},[_c('div',{staticClass:"window",class:{ paused: _vm.IO.Paused },on:{"click":_vm.pauseplayAll}}),_c('transition',{attrs:{"name":"fadebuffer"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IO.Buffering),expression:"IO.Buffering"}],staticClass:"buffering"})]),_c('vimeo-player',{ref:"EndingVideo",staticClass:"video-embed",attrs:{"video-id":"532423688","options":{
                    responsive: true,
                    controls: false,
                    autopause: false
                  }},on:{"bufferstart":function($event){return _vm.SetBuffering(true)},"bufferend":function($event){return _vm.SetBuffering(false)},"timeupdate":_vm.UpdateTime}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IO.Ended),expression:"IO.Ended"}],staticClass:"donation fill-height"},[_c('a',{attrs:{"href":"https://www.coliseum.org.uk/your-coliseum-needs-you/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/donation-ask.png"),"width":"100%"}})])])],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IO.Options),expression:"IO.Options"}],staticClass:"options"},[_c('v-responsive',{attrs:{"aspect-ratio":5 / 1}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('router-link',{staticClass:"pa-2 option",attrs:{"to":{
                          path: '/',
                          query: {
                            d: _vm.$store.state.viewingdate,
                            playagain: true
                          }
                        },"tabindex":"0","role":"button"}},[_c('span',[_vm._v("Play again?")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('a',{staticClass:"pa-2 option right",attrs:{"href":"https://www.coliseum.org.uk/your-coliseum-needs-you/","target":"_blank","tabindex":"0","role":"button"}},[_c('span',[_vm._v("Donate to Oldham Coliseum")])])])],1)],1)],1)],1)])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }