<template>
  <v-app>
    <v-main>
      <div class="centered">
        <a
          class="download-programme"
          href="https://whodunnit.coliseum.org.uk/Whodunnit-at-the-Coliseum-Programme.pdf"
          target="_blank"
        >
          <img
            src="@/assets/download-programme.png"
            alt="Download the programme"
            width="100%"
          />
        </a>
        <v-responsive :aspect-ratio="41 / 35">
          <div class="video-block">
            <v-responsive :aspect-ratio="16 / 9">
              <transition name="fade">
                <div class="videos fill-height" v-show="!IO.Ended">
                  <div
                    class="window"
                    :class="{ paused: IO.Paused }"
                    @click="pauseplayAll"
                  ></div>
                  <transition name="fadebuffer">
                    <div class="buffering" v-show="IO.Buffering"></div>
                  </transition>
                  <vimeo-player
                    ref="EndingVideo"
                    video-id="532423688"
                    class="video-embed"
                    :options="{
                      responsive: true,
                      controls: false,
                      autopause: false
                    }"
                    @bufferstart="SetBuffering(true)"
                    @bufferend="SetBuffering(false)"
                    @timeupdate="UpdateTime"
                  />
                </div>
              </transition>
              <div class="donation fill-height" v-show="IO.Ended">
                <a
                  href="https://www.coliseum.org.uk/your-coliseum-needs-you/"
                  target="_blank"
                >
                  <img src="@/assets/donation-ask.png" width="100%" />
                </a>
              </div>
            </v-responsive>
            <transition name="fade">
              <div class="options" v-show="IO.Options">
                <v-responsive :aspect-ratio="5 / 1">
                  <v-container fill-height>
                    <v-row justify="center" class="fill-height">
                      <v-col cols="6">
                        <router-link
                          :to="{
                            path: '/',
                            query: {
                              d: $store.state.viewingdate,
                              playagain: true
                            }
                          }"
                          class="pa-2 option"
                          tabindex="0"
                          role="button"
                        >
                          <span>Play again?</span>
                        </router-link>
                      </v-col>
                      <v-col cols="6">
                        <a
                          href="https://www.coliseum.org.uk/your-coliseum-needs-you/"
                          target="_blank"
                          class="pa-2 option right"
                          tabindex="0"
                          role="button"
                        >
                          <span>Donate to Oldham Coliseum</span>
                        </a>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-responsive>
              </div>
            </transition>
          </div>
        </v-responsive>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "EndingSuccess",
  title: "The End",
  data() {
    return {
      IO: {
        Paused: true,
        Buffering: true,
        Ended: false,
        Options: false
      }
    };
  },
  methods: {
    pauseplayAll() {
      if (!this.IO.Paused) {
        Promise.allSettled([this.$refs.EndingVideo.pause()]).then(() => {
          this.IO.Paused = true;
        });
      } else {
        this.$refs.EndingVideo.play().then(() => {
          this.IO.Paused = false;
        });
      }
    },
    SetBuffering(val) {
      this.IO.Buffering = val;
    },
    UpdateTime(val) {
      if (val.seconds >= 13) {
        this.IO.Options = true;
      }
      if (val.seconds >= 94) {
        this.IO.Ended = true;
      }
    }
  },
  mounted() {
    this.$refs.EndingVideo.play().then(() => {
      this.IO.Paused = false;
    });
    this.$refs.EndingVideo.player.disableTextTrack();
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fadebuffer-enter-active,
.fadebuffer-leave-active {
  transition: 0.7s;
}
.fadebuffer-enter, .fadebuffer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

main {
  height: 100vh;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-no-lines.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    transition: 1s;
    filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
    -webkit-filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
  }
  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px 7px 14px;
  }

  .window {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: url(../assets/cursor-pause.png), auto;

    &.paused {
      cursor: url(../assets/cursor-play.png), auto;
    }
  }

  .buffering {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: url(../assets/loading-video-v2.png) no-repeat;
    background-size: 100% 100%;
  }

  &.guess {
    &:before {
      filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
      -webkit-filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
    }
    .download-programme {
      filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
      -webkit-filter: invert(20%) sepia(0.8) brightness(0.4) grayscale(0);
    }
  }

  .donation {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-52%));
  width: 55.2%;
  width: 55.2%;
  //min-width: 55.2%;
  z-index: 1;
}
.download-programme {
  display: block;
  position: absolute;
  top: 4px;
  right: -7px;
  width: 42%;
  z-index: 100;
  transition: 0.6s ease-out;
  filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);
  -webkit-filter: invert(0) sepia(0.2) brightness(0.5) grayscale(0.3);

  &:hover {
    filter: none !important;
    -webkit-filter: none !important;
  }
}

.vimeo-embed {
  position: absolute;
  top: 0;
  left: 4px;
}

.options {
  position: relative;
  transition: 1s ease-out;
  top: 4%;
  padding-top: 0.6vh;
  text-align: center;
  padding-top: 15px;

  .option {
    overflow: hidden;
    font-family: "Franchise";
    background: url(../assets/btn-left-black.png) no-repeat;
    background-size: 100% 100%;
    width: calc(100% - 30px);
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    transition: 0.2s ease-out;
    opacity: 0.8;
    position: relative;
    margin: 0 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.4vw;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }

    &.right {
      background-image: url(../assets/btn-right-black.png);
    }
    &.anyselected:not(.selected) {
      filter: brightness(0.3) sepia(0.6);
      -webkit-filter: brightness(0.3) sepia(0.6);
    }
  }
}
</style>
